<!-- TITLE -->
<div class="flex flex-col items-center p-4 container mx-auto w-full">
    
    <!-- TITLE & HEADER -->
    <h2 class="text-darkgray/50 dark:text-lightgray/50 font-medium text-xl mb-1 lg:font-bold lg:text-lg">
        {{title}}
    </h2>

    <h3 class="text-darkgray dark:text-lightgray/60 font-bold text-3xl mb-4 lg:text-4xl">
        {{header}}
    </h3>
    
    <!-- VENUE SELECTOR -->
    <ng-container *ngIf="events() && events().length">
        
        <!-- MOBILE & TABLET -->
        <div class="lg:hidden h-11 w-full">
            <app-selector class="justify-center" [venues]="venues()" (selected)="filterByVenue($event)"/>
        </div>
    
    
        <!-- DESKTOP -->
        <div  class="hidden lg:flex w-full justify-center gap-3 my-2">
            <button (click)="filterByVenue(venue.value.name)" *ngFor="let venue of venues() | keyvalue" [ngClass]="{'pill-active': venue.value.active}" class="pill font-semibold lg:text-xs xl:text-base">
                {{venue.value.name | uppercase}}
            </button>
        </div>

    </ng-container>
   
</div>

<!-- EVENTS -->
<ng-container *ngIf="events() else emptyEvents">
    
    <!-- LOADER -->
    <ng-container *ngIf="events().length === 0 else eventCards">

        <div class="h-[calc(theme(height.screen)-200px)] flex items-center bg-white justify-center">
            <app-loader class="flex justify-center"/>
        </div>

    </ng-container>

    <!-- CARDS -->
    <ng-template #eventCards>

        <div [ngClass]="{'justify-center items-center bg-transparent mt-32 lg:mt-0 lg:bg-white': !events}" class="h-full p-4 lg:pt-8 lg:pb-48 bg-white dark:bg-gray-900 flex flex-col md:items-center gap-5 overflow-y-auto">
            <app-card class="flex w-full justify-center" *ngFor="let event of filteredEvents()" [event]="event" (cardEvents)="nextStep(event)" />
        </div>

    </ng-template>

</ng-container>

<!-- NO EVENTS -->
<ng-template #emptyEvents>
    <div class="h-full w-full bg-white text-center py-5">
        <h5 class="font-main text-xl text-darkgray mb-2">
           Event Schedule
        </h5>
        <p class="font-main text-secondary">
            There are no events available at this time
        </p>
    </div>
</ng-template>

