import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectEventComponent } from './select-event.component';
import { SelectorModule } from 'src/app/shared/components/globals/selector/selector.module';
import { CardModule } from 'src/app/shared/components/globals/card/card.module';
import { LoaderModule } from 'src/app/shared/components/globals/loader/loader.module';



@NgModule({
  declarations: [
    SelectEventComponent,
  ],
  imports: [
    CommonModule,
    SelectorModule,
    CardModule,
    LoaderModule
  ],
  exports: [
    SelectEventComponent
  ]
})
export class SelectEventModule { }
