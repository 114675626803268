import { Component, inject, Signal ,signal, computed, WritableSignal, OnInit } from '@angular/core';
import { Event, Venues } from 'src/app/shared/models/event.model';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { AvailabilityService } from 'src/app/core/services/availability.service';
import { PriceScale } from 'src/app/shared/models/availabilty/section.model';


@Component({
  selector: 'app-select-event',
  templateUrl: './select-event.component.html',
  styleUrls: ['./select-event.component.css'],
})

export class SelectEventComponent implements OnInit {

  constructor(
    private availability: AvailabilityService<Event, PriceScale>,
  ) {}
  
  //SERVICES
  private router:        Router         = inject(Router);
  private route:         ActivatedRoute = inject(ActivatedRoute);	
  
  //STATE
  protected params:      Params                 = this.route.snapshot.queryParams;
  protected data:        Data                   = this.route.snapshot.data;
  protected events:      Signal<Event[]>        = this.availability.entity
  protected filter:      WritableSignal<string> = signal('ALL');

  //TEMPLATE VARS
  public   title!:  string;
  public   header:  string  = 'Event Schedule';
  public   type!:   'event' | 'package' |'exchange' 
 
  // COMPUTED
  /**
   * Si **filter** no es 'ALL', filtra aplicando el valor de filter y retorna los eventos filtrados que contengan la venue por la cual se han filtrado. 
   */
  protected filteredEvents: Signal<Event[]> = computed(()=>{
    
    if(this.filter() !== 'ALL'){
     const eventsFiltered = this.events().filter(event => event.venue === this.filter());
     return eventsFiltered; 
    }
    
    return this.events()
  })

  /**
   * Formatea un Objeto Venues, cuando **events** tenga datos, itera y formatea un objeto con las venues disponibles.
   */
  protected venues: Signal<Venues> = computed(()=>{
    
    const venues: Venues = {'ALL': {name: 'ALL', active: true}};
   
    this.events().forEach(event => venues[event.venue] = {name: event.venue, active: false})
    
    return venues;
  })

  // METHODS
  ngOnInit(): void {
    this.initComponent();
  }

  /**
   * Inicializa el componente y setea el titulo del componente con el valor de **data['title']**.
   */
  protected initComponent(): void {
    this.title = this.data['title'];
    this.type  = this.data['type'];
  }

  /**
   * Permuta el valor del filtro, cambiando el actual por el nuevo y setea filter con el nuevo valor. Lo que recalculará **filteredEvents**.
   * Si el newFilter y el ActiveFilter son los mismos, reseteamos a 'ALL'
   * @param {string} venue - Key de la Venue a filtrar
   */
  protected filterByVenue(venue: keyof Venues): void {
    const activeFilter  = Object.values(this.venues()).filter(venue => venue.active === true)[0];
    const newFilter     = this.venues()[venue];
    
    if(activeFilter.name === newFilter.name){
      this.resetFilters(activeFilter);
      return;
    }

    activeFilter.active = false;
    newFilter.active    = true;
  
    this.filter.set(newFilter.name);
  }
  
  /**
   * Formatea los parametros de la ruta añadiendo el id del evento y la venue.
   * Guarda de manera global el evento seleccionado y navega al siguiente step del flow.
   * @param { object } event 
   */
  protected nextStep(event: Event): void{

    const destination = () => {
      switch(this.type){
        case 'event':     return '/buy-tickets/';
        case 'package':   return '/buy-packages/';
        case 'exchange':  return '/exchange/';
      }
    } 

    const route = destination() + 'select-friends';

    let params: any = {
      event: event.id,
      venue: event.venue,
    }

    if(this.params['transaction']){
      params.transaction = this.params['transaction'];
    }
    
    this.availability.setEntitySelected(event);
    this.router.navigate([route], {queryParams: params});
  }

  /**
   * Cambia el estado del filtro actual a false y reestablece el filtro por defecto 'ALL'.
   * Este método solo se llama cuando pulsamos dos veces sobre el mismo filtro y produce un efecto
   * toggle de activar / desactivar el filtro devolviendo al estado original.
   * @param {Venues['index']} activeFilter 
   */
  private resetFilters(activeFilter: Venues['']): void {

    activeFilter.active = false;

    this.venues()['ALL'].active = true;

    this.filter.set('ALL')

  }
}

